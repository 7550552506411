import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Cabin } from 'src/app/models/car/cabin/cabin';
import { CabinPosition } from 'src/app/models/car/cabin/cabin-position';
import { CarDoor } from 'src/app/models/car/cabin/car-door';
import { CarWindow } from 'src/app/models/car/cabin/car-window';
import { CarService } from 'src/app/service/car/car.service';

@Component({
  selector: 'app-locking',
  templateUrl: './locking.component.html',
  styleUrls: ['./locking.component.css']
})
export class LockingComponent implements OnInit {
  private cabin: Cabin;
  private carId: number;

  cabinPosition = CabinPosition;
  alertText = '';

  constructor(
    private route: ActivatedRoute,
    private carService: CarService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.carId = +params.get('carId');
    });
    this.cabin = this.carService.getById(this.carId).cabin;
  }

  settingsLink(): string {
    return '/cars/' + this.carId + '/settings';
  }

  backLink(): string {
    return '/cars/' + this.carId;
  }

  getDoorOfPosition(cabinPosition: CabinPosition): CarDoor {
    return this.cabin.doors.find(door => door.cabinPosition === cabinPosition);
  }

  getWindowOfPosition(cabinPosition: CabinPosition): CarWindow {
    return this.cabin.windows.find(window => window.cabinPosition === cabinPosition);
  }

  getLockIcon(): string {
    return this.isShutdown() ? 'lock_closed' : 'lock_open';
  }

  getLockText(): string {
    return this.isShutdown() ? 'Türen entriegeln' : 'Alles verriegeln';
  }

  isShutdown(): boolean {
    return this.cabin.isShutdown();
  }

  handleLock(): void {
    if (this.isShutdown()) {
      this.cabin.unlockDoors();
    } else {
      this.cabin.shutdown();
      if (!this.cabin.areWindowsClosed()) {
        const cabinPosition = this.cabin.windows.find(window => window.isBroken).cabinPosition;
        this.setAlertStatus(cabinPosition);
      }
    }
  }

  setAlertStatus(cabinPosition: string): void {
    this.alertText = 'Das Fenster ' + cabinPosition + ' ist kaputt.';
  }

  closeAlert(): void {
    this.alertText = '';
  }
}
