<div class="d-flex flex-column">
  <div class="title">Werkstatt</div>
  <div class="first-content mt-3">
    <app-search [name]="'Suche'" [selectedIndex]="indexOfFavoriteStation" [items]="createDisplayNames()"
      (index)="handleSearchIndex($event)">
    </app-search>
  </div>
  <div class="second-content d-flex flex-column mt-3">
    <div class="d-flex flex-row mb-1">
      <span class="left-row">Name</span>
      <span>{{selectedRepairStation.name}}</span>
    </div>
    <div class="d-flex flex-row mb-1">
      <span class="left-row">Straße</span>
      <span>{{selectedRepairStation.street}} {{selectedRepairStation.streetAppendix}}</span>
    </div>
    <div class="d-flex flex-row mb-1">
      <span class="left-row">Stadt</span>
      <span>{{selectedRepairStation.plz}} {{selectedRepairStation.city}}</span>
    </div>
    <div class="d-flex flex-row mb-1">
      <span class="left-row">Entfernung</span>
      <span>{{selectedRepairStation.getDistanceDisplay()}}</span>
    </div>
  </div>
</div>