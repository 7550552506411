import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClimateComponent } from './components/dashboard/climate/climate.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DiagnosisComponent } from './components/dashboard/diagnosis/diagnosis.component';
import { FuelComponent } from './components/dashboard/fuel/fuel.component';
import { LockingComponent } from './components/dashboard/locking/locking.component';
import { RepairComponent } from './components/dashboard/repair/repair.component';
import { SettingsComponent } from './components/dashboard/settings/settings.component';
import { SearchComponent } from './components/generics/search/search.component';

const routes: Routes = [
  { path: '', redirectTo: 'cars/0', pathMatch: 'full' },
  { path: 'test', component: SearchComponent },
  { path: 'cars/:carId', component: DashboardComponent },
  { path: 'cars/:carId/locking', component: LockingComponent },
  { path: 'cars/:carId/settings', component: SettingsComponent },
  { path: 'cars/:carId/climate', component: ClimateComponent },
  { path: 'cars/:carId/diagnosis', component: DiagnosisComponent },
  { path: 'cars/:carId/repair', component: RepairComponent },
  { path: 'cars/:carId/fuel', component: FuelComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
