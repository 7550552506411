import { NgModule } from '@angular/core';
import { GenericComponentsModule } from '../generics/generics.module';
import { LockingComponent } from './locking/locking.component';
import { DashboardComponent } from './dashboard.component';
import { FuelComponent } from './fuel/fuel.component';
import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { ClimateComponent } from './climate/climate.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { DiagnosisItemComponent } from './diagnosis/diagnosis-item/diagnosis-item.component';
import { SettingsComponent } from './settings/settings.component';
import { RepairComponent } from './repair/repair.component';
import { DoorButtonComponent } from './locking/door-button/door-button.component';
import { WindowButtonComponent } from './locking/window-button/window-button.component';
import { TargetTemperatureComponent } from './climate/target-temperature/target-temperature.component';
import { ScheduleComponent } from './climate/schedule/schedule.component';
import { ProfileComponent } from './settings/profile/profile.component';
import { WarningsComponent } from './settings/warnings/warnings.component';
import { OthersComponent } from './settings/others/others.component';
import { MessageBoxItemComponent } from './message-box/message-box-item/message-box-item.component';
import { SendReportComponent } from './repair/send-report/send-report.component';
import { SearchRepairComponent } from './repair/search-repair/search-repair.component';
import { ReportPersonalDataComponent } from './repair/report-personal-data/report-personal-data.component';
import { ClimateBoxComponent } from './climate/climate-box/climate-box.component';
import { FuelFinderComponent } from './fuel/fuel-finder/fuel-finder.component';
import { FuelInfoComponent } from './fuel/fuel-info/fuel-info.component';

@NgModule({
  declarations: [
      DashboardComponent,

      ClimateComponent,
      ScheduleComponent,
      TargetTemperatureComponent,
      ClimateBoxComponent,

      DiagnosisComponent,
      DiagnosisItemComponent,

      FuelComponent,
      FuelInfoComponent,
      FuelFinderComponent,

      LockingComponent,
      DoorButtonComponent,
      WindowButtonComponent,

      MessageBoxComponent,
      MessageBoxItemComponent,

      RepairComponent,
      ReportPersonalDataComponent,
      SearchRepairComponent,
      SendReportComponent,

      SettingsComponent,
      OthersComponent,
      ProfileComponent,
      WarningsComponent,
  ],
  imports: [
    GenericComponentsModule
  ]
})
export class DashboardComponentsModule { }
