import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RepairStation } from 'src/app/models/repair-station/repair-station';
import { RepairStationService } from 'src/app/service/repair-station/repair-station.service';

@Component({
  selector: 'app-search-repair',
  templateUrl: './search-repair.component.html',
  styleUrls: ['./search-repair.component.css']
})
export class SearchRepairComponent implements OnInit {
  @Input() favoriteRepairStationId: number;
  @Output() selected = new EventEmitter<RepairStation>();

  selectedRepairStation: RepairStation;
  repairStations: RepairStation[];
  indexOfFavoriteStation: number;

  constructor(
    private repairStationService: RepairStationService
  ) { }

  ngOnInit(): void {
    this.repairStations = this.repairStationService.getMockWorkshops();
    this.selectedRepairStation = this.getPreselectedStation();
    this.indexOfFavoriteStation = this.repairStations.findIndex(station => station.id === this.selectedRepairStation.id);
  }

  handleSearchIndex(index: number): void {
    this.selectedRepairStation = this.repairStations[index];
    this.selected.emit(this.selectedRepairStation);
  }

  createDisplayNames(): string[] {
    return this.repairStations.map(station => station.displayWithDistance());
  }

  getPreselectedStation(): RepairStation {
    const favoriteRepairStation = this.repairStationService.getRepairStationById(this.favoriteRepairStationId);
    return favoriteRepairStation === undefined ? this.repairStations[0] : favoriteRepairStation;
  }
}
