import { Injectable } from '@angular/core';
import { Temperature } from 'src/app/models/weather/temperature';
import { TemperatureUnit } from 'src/app/models/weather/temperature-unit';
import { Weather } from 'src/app/models/weather/weather';

@Injectable({
    providedIn: 'root'
})
export class WeatherService {
    public weather: Weather;

    constructor() {
        this.createMockWeather();
    }

    createMockWeather(): void {
        this.weather = new Weather(new Temperature(10, TemperatureUnit.C), false);
    }
}
