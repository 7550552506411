import { Component, Input, OnInit } from '@angular/core';
import { MessageItem } from 'src/app/models/messages/message-item';
import { MessageType } from 'src/app/models/messages/message-type';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent implements OnInit {
  @Input() messages: MessageItem[];

  messageType = MessageType;

  ngOnInit(): void { }

  hasMessages(): boolean {
    return this.messages.length > 0;
  }

  messagesFromType(type: MessageType): MessageItem[] {
    return this.messages.filter(message => message.type === type);
  }

  hasMessagesFromType(type: MessageType): boolean {
    return this.messagesFromType(type).length > 0;
  }

  hasMessagesNotFromType(type: MessageType): boolean {
    return this.messagesFromType(this.messageType.error).length !== this.messages.length;
  }
}
