<div class="title mb-3">
  Warnungen
</div>

<div class="first-content">
  <div class="d-flex flex-column">
    <div class="d-flex flex-row align-items-center">
      <div class="w-50">Reichweitenwarnung</div>

      <div class="w-50">
        <a class="btn" (click)="handleRangeWarningDecrement()">
          <mat-icon class="caret-fill" svgIcon="caret-down-fill"></mat-icon>
        </a>
        <span class="display-text">
          {{profile.rangeWarningInKm}} km
        </span>
        <a class="btn" (click)="handleRangeWarningIncrement()">
          <mat-icon class="caret-fill" svgIcon="caret-up-fill"></mat-icon>
        </a>
      </div>
    </div>

    <div class="d-flex flex-row align-items-center">
      <div class="w-50">Reifendruckwarnung</div>

      <div class="w-50">
        <a class="btn" (click)="handlePressureWarningDecrement()">
          <mat-icon class="caret-fill" svgIcon="caret-down-fill"></mat-icon>
        </a>
        <span class="display-text">
          {{+profile.wheelPressureWarningInBar.toFixed(2)}} bar
        </span>
        <a class="btn" (click)="handlePressureWarningIncrement()">
          <mat-icon class="caret-fill" svgIcon="caret-up-fill"></mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>
