import { Component, Input, OnInit } from '@angular/core';
import { CarWindow } from 'src/app/models/car/cabin/car-window';

@Component({
  selector: 'app-window-button',
  templateUrl: './window-button.component.html',
  styleUrls: ['./window-button.component.css']
})
export class WindowButtonComponent implements OnInit {
  @Input() carWindow: CarWindow;

  alertText = '';

  constructor() { }

  ngOnInit(): void { }

  getIcon(): string {
    return this.carWindow.isClosed ? 'window_closed' : 'window_open';
  }

  handleClick(): void {
    if (!this.carWindow.toggleStatus()) {
      this.setAlertStatus();
    }
  }

  setAlertStatus(): void {
    this.alertText = 'Das Fenster ' + this.carWindow.cabinPosition + ' ist kaputt.';
  }

  closeAlert(): void {
    this.alertText = '';
  }
}
