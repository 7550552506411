import { TemperatureUnit } from './temperature-unit';

export class Temperature {
    constructor(
        public value: number,
        public unit: TemperatureUnit
    ) { }

    display(temperatureUnit: TemperatureUnit): string {
        if (this.unit !== temperatureUnit) {
            this.convert();
            this.unit = temperatureUnit;
        }
        return this.value + ' ' + this.unit;
    }

    private convert(): void {
        if (this.unit === TemperatureUnit.C) {
            this.convertCToF();
        } else {
            this.convertFToC();
        }
    }

    private convertCToF(): void {
        this.value = Math.round(this.value * 9 / 5 + 32);
    }

    private convertFToC(): void {
        this.value = Math.round((this.value - 32) * 5 / 9);
    }
}
