import { Diagnosis } from './diagnosis/diagnosis';
import { Light } from './body/light';
import { Cabin } from './cabin/cabin';
import { ClimateControl } from './cabin/climate-control';
import { Wheel } from './chassis/wheel';
import { Obd } from './obd/obd';
import { FuelSystem } from './powertrain/fuel-system';
import { CarDoor } from './cabin/car-door';
import { CabinPosition } from './cabin/cabin-position';
import { CarWindow } from './cabin/car-window';
import { ChassisPosition } from './chassis/chassis-position';
import { BodyPosition } from './body/body-position';
import { Temperature } from '../weather/temperature';
import { TemperatureUnit } from '../weather/temperature-unit';
import { DiagnosisItem } from './diagnosis/diagnosis-item';

export class Car {
    private static ID = 0;

    readonly id = Car.ID++;

    diagnosis: Diagnosis;

    constructor(
        readonly name: string,
        readonly cabin: Cabin,
        readonly wheels: Wheel[],
        readonly lights: Light[],
        readonly climateControl: ClimateControl,
        readonly fuelSystem: FuelSystem,
        readonly obd: Obd,
    ) {
        this.diagnosis = new Diagnosis(obd, wheels, lights, cabin);
    }

    static createMockCar(diagnosisItems: DiagnosisItem[]): Car {
        return new Car(
            'name',
            new Cabin([
                new CarDoor(CabinPosition.Row1Left, true),
                new CarDoor(CabinPosition.Row1Right, true),
                new CarDoor(CabinPosition.Row2Left, true),
                new CarDoor(CabinPosition.Row2Right, true),
                new CarDoor(CabinPosition.Trunk, true)], [
                new CarWindow(CabinPosition.Row1Left, true),
                new CarWindow(CabinPosition.Row1Right, true),
                new CarWindow(CabinPosition.Row2Left, true),
                new CarWindow(CabinPosition.Row2Right, true)]),
            [
                new Wheel(ChassisPosition.Row1Left, 2),
                new Wheel(ChassisPosition.Row1Right, 2),
                new Wheel(ChassisPosition.Row2Left, 2),
                new Wheel(ChassisPosition.Row2Right, 2)],
            [
                new Light(BodyPosition.Back, false),
                new Light(BodyPosition.Front, false),
                new Light(BodyPosition.Row1Left, false),
                new Light(BodyPosition.Row1Right, false),
                new Light(BodyPosition.Row2Left, false),
                new Light(BodyPosition.Row2Right, false)],
            new ClimateControl(new Temperature(21, TemperatureUnit.C), new Temperature(14, TemperatureUnit.C)),
            new FuelSystem(44, 'diesel', 100, 12.3),
            new Obd(diagnosisItems)
        );
    }
}
