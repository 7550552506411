<div class="d-flex flex-row justify-content-center main">
  <div class="d-flex flex-column target-temperature mr-3">
    <a class="btn" (click)="handlePlus()">
      <mat-icon class="caret-fill" svgIcon="caret-up-fill"></mat-icon>
    </a>
    <span class="display-target-temperature bold align-self-center">
      {{climateControl.targetTemperature.display(temperatureUnit)}}
    </span>
    <a class="btn" (click)="handleMinus()">
      <mat-icon class="caret-fill" svgIcon="caret-down-fill"></mat-icon>
    </a>
  </div>
  <div class="d-flex flex-column justify-content-around ml-3">
    <div class="d-flex flex-column align-items-center">
      <span>innen</span>
      <span class="bold">{{climateControl.insideTemperature.display(temperatureUnit)}}</span>
    </div>
    <div class="d-flex flex-column align-items-center">
      <span>außen</span>
      <span class="bold">{{outsideTemperature.display(temperatureUnit)}}</span>
    </div>
  </div>
</div>
