import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  @Input() icon: string;
  @Input() name: string;
  @Input() destination: string;
  @Input() badge: boolean;

  constructor() { }

  ngOnInit(): void { }
}
