export class RepairStation {
    constructor(
        public id: number,
        public name: string,
        public phone: string,
        public city: string,
        public plz: string,
        public street: string,
        public streetAppendix: string,
        public distanceInMeter: number) { }

    getDistance(): number {
        return this.distanceInMeter;
    }

    getDistanceDisplay(): string {
        return this.distanceInMeter < 1000
            ? ((Math.round(this.distanceInMeter / 10) * 10) + ' m')
            : ((Math.round(this.distanceInMeter / 100) / 10) + ' km');
    }

    display(): string {
        return this.name + ', '
            + this.plz + ' '
            + this.city + ', '
            + this.street + ' '
            + this.streetAppendix;
    }

    displayWithDistance(): string {
        return this.getDistanceDisplay() + ', '
            + this.name + ', '
            + this.plz + ' '
            + this.city;
    }
}
