import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent implements OnInit {
  @Input() items: string[];
  @Input() selectedIndex = 0;
  @Output() index = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void { }

  select(index: number): void {
    this.selectedIndex = index;
    this.index.emit(index);
  }
}
