import { Light } from '../body/light';
import { Cabin } from '../cabin/cabin';
import { Wheel } from '../chassis/wheel';
import { Obd } from '../obd/obd';
import { DiagnosisItem } from './diagnosis-item';

export class Diagnosis {
    lastCheck: string;

    constructor(
        private obd: Obd,
        private wheels: Wheel[],
        private lights: Light[],
        private cabin: Cabin
    ) {
        this.lastCheck = new Date().toLocaleString();
    }

    computeDiagnosisItems(wheelPressureWarningInBar: number): DiagnosisItem[] {
        this.lastCheck = new Date().toLocaleString();
        return this.obd.diagnosisItems.concat(
            this.wheelPressureWarnings(wheelPressureWarningInBar),
            this.brokenWindowWarnings(),
            this.brokenLightWarnings()
        );
    }

    wheelPressureWarnings(wheelPressureWarningInBar: number): DiagnosisItem[] {
        const wheelDiagnosisItems = [];
        const lowPressureWheels = this.wheels.filter(wheel => wheel.pressureInBar <= wheelPressureWarningInBar);
        lowPressureWheels.forEach(wheel => wheelDiagnosisItems.push(wheel.warning));
        return wheelDiagnosisItems;
    }

    brokenWindowWarnings(): DiagnosisItem[] {
        const windowDiagnosisItems = [];
        const brokenWindows = this.cabin.windows.filter(window => window.isBroken);
        brokenWindows.forEach(window => windowDiagnosisItems.push(window.warning));
        return windowDiagnosisItems;
    }

    brokenLightWarnings(): DiagnosisItem[] {
        const lightDiagnosisItems = [];
        const brokenLights = this.lights.filter(light => light.isBroken);
        brokenLights.forEach(light => lightDiagnosisItems.push(light.warning));
        return lightDiagnosisItems;
    }
}
