import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-success-button',
  templateUrl: './success-button.component.html',
  styleUrls: ['./success-button.component.css']
})
export class SuccessButtonComponent implements OnInit {
  @Input() success: boolean;
  @Input() icon: string;
  @Output() clicked = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void { }

  handleClick(): void {
    this.clicked.emit();
  }
}
