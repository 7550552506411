import { CabinPosition } from './cabin-position';

export class CarDoor {
    constructor(
        readonly cabinPosition: CabinPosition,
        public isLocked: boolean,
    ) { }

    public toggleStatus(): void {
        this.isLocked = !this.isLocked;
    }

    lock(): void {
        this.isLocked = true;
    }

    unlock(): void {
        this.isLocked = false;
    }
}
