export class FuelStation {
    private static ID = 0;

    public readonly id = FuelStation.ID++;
    public readonly price = +(1 + 0.5 * Math.random()).toFixed(2);
    public readonly priceStr = this.price.toFixed(2) + ' €/l';

    constructor(
        public name: string,
        public phone: string,
        public city: string,
        public plz: string,
        public street: string,
        public streetAppendix: string,
        public distanceInKm: number,
        public url: URL
    ) { }
}
