import { DiagnosisType } from '../diagnosis/diagnosis-type';
import { DiagnosisItem } from '../diagnosis/diagnosis-item';
import { CabinPosition } from './cabin-position';

export class CarWindow {
    private _isBroken = false;
    warning: DiagnosisItem;

    constructor(
        readonly cabinPosition: CabinPosition,
        private _isClosed: boolean,
    ) {
        this.createWarning();
    }

    get isBroken(): boolean {
        return this._isBroken;
    }

    get isClosed(): boolean {
        return this._isClosed;
    }

    public toggleStatus(): boolean {
        if (this._isBroken) {
            return false;
        }
        this._isClosed = !this._isClosed;
        return true;
    }

    oneShotClose(): boolean {
        if (this._isBroken) {
            return false;
        }
        this._isClosed = true;
        return this._isBroken;
    }

    break(): void {
        this._isBroken = true;
    }

    repair(): void {
        this._isBroken = false;
    }

    createWarning(): void {
        this.warning = new DiagnosisItem(
            'Fenster defekt',
            'Das Fenster ' + this.cabinPosition + ' lässt sich nicht schließen. ' +
            'Tauschen Sie die Sicherung aus oder kontaktieren Sie die Werkstatt.',
            'B118E', DiagnosisType.warning
        );
    }
}
