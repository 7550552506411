<div class="title mb-3">
  Persönliche Daten
</div>

<div class="first-content">
  <div class="p-2 my-mat mb-4">
    <mat-form-field class="w-50 pr-2">
      <mat-label>Name</mat-label>
      <input matInput [(ngModel)]="profile.name">
    </mat-form-field>
    <mat-form-field class="w-50">
      <mat-label>Telefonnummer</mat-label>
      <input matInput [(ngModel)]="profile.phone">
    </mat-form-field>
  </div>
  <app-search [name]="'Lieblingswerkstatt'" [selectedIndex]="indexOfFavoriteStation" [items]="createDisplayNames()"
    (index)="handleSearchIndex($event)">
  </app-search>
</div>