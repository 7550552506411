<div class="first-content w-100 d-flex titlebar">
  <div class="bold align-self-center ">
    {{diagnosisItem.title}}
  </div>
  <mat-icon class="triangle align-self-center" [class]="isError() ? 'error-img' : 'warning-img'"
    svgIcon="warning_triangle"></mat-icon>
  <div class="chevron-width align-self-center">
    <a class="btn arrow" [class]="diagnosisItem.showDetails ? 'chevron up' : 'chevron down'"
      (click)="diagnosisItem.toggleShowDetails()"></a>
  </div>
</div>
<div class="second-content" *ngIf="diagnosisItem.showDetails">
  <div class="mt-3 mb-3 d-flex flex-column justify-content-center error-code">
    <div class="error-code-size align-self-center">
      Fehlercode: {{diagnosisItem.errorCode}}
    </div>
  </div>
  <div>
    Fehler gefunden am: {{diagnosisItem.date}}
  </div>
  <div class="mt-3 text-size">
    {{diagnosisItem.text}}
  </div>
  <div class="mt-3 error" *ngIf="isError()">
    Kontaktieren Sie sofort die Werkstatt!
  </div>
</div>