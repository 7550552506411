import { Component, Input, OnInit } from '@angular/core';
import { DiagnosisType } from 'src/app/models/car/diagnosis/diagnosis-type';
import { DiagnosisItem } from 'src/app/models/car/diagnosis/diagnosis-item';

@Component({
  selector: 'app-diagnosis-item',
  templateUrl: './diagnosis-item.component.html',
  styleUrls: ['./diagnosis-item.component.css']
})
export class DiagnosisItemComponent implements OnInit {
  @Input() diagnosisItem: DiagnosisItem;

  ngOnInit(): void { }

  isError(): boolean {
    return this.diagnosisItem.type === DiagnosisType.error;
  }
}
