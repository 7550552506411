import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageItem } from 'src/app/models/messages/message-item';
import { MessageType } from 'src/app/models/messages/message-type';

@Component({
  selector: 'app-message-box-item',
  templateUrl: './message-box-item.component.html',
  styleUrls: ['./message-box-item.component.css']
})
export class MessageBoxItemComponent implements OnInit {
  @Input() messages: MessageItem[];

  messageType = MessageType;
  carId: number;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.carId = +params.get('carId');
    });
  }

  hasMessages(): boolean {
    return this.messages.length > 0;
  }

  getType(): MessageType {
    return this.messages[0].type;
  }

  destinationLink(message: MessageItem): string {
    return '/cars/' + this.carId + '/' + message.destination;
  }
}
