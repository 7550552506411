import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClimateControl } from 'src/app/models/car/cabin/climate-control';
import { ClimateSchedule } from 'src/app/models/profile/climate-schedule';
import { Temperature } from 'src/app/models/weather/temperature';
import { TemperatureUnit } from 'src/app/models/weather/temperature-unit';
import { CarService } from 'src/app/service/car/car.service';
import { ProfileService } from 'src/app/service/profile/profile.service';
import { WeatherService } from 'src/app/service/weather/weather.service';

@Component({
  selector: 'app-climate',
  templateUrl: './climate.component.html',
  styleUrls: ['./climate.component.css']
})
export class ClimateComponent implements OnInit {
  private carId: number;

  climateControl: ClimateControl;
  climateSchedules: ClimateSchedule[];
  outsideTemperature: Temperature;
  temperatureUnit: TemperatureUnit;

  constructor(
    private route: ActivatedRoute,
    private carService: CarService,
    private profileService: ProfileService,
    private weatherService: WeatherService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.carId = +params.get('carId');
    });
    const selectedProfile = this.profileService.getByCarId(this.carId);
    this.climateControl = this.carService.getById(this.carId).climateControl;
    this.climateSchedules = selectedProfile.climateSchedules;
    this.temperatureUnit = selectedProfile.temperatureUnit;
    this.outsideTemperature = this.weatherService.weather.currentTemperature;
  }

  settingsLink(): string {
    return '/cars/' + this.carId + '/settings';
  }

  backLink(): string {
    return '/cars/' + this.carId;
  }

  handleTargetTemperatureToggle(active: boolean): void {
    this.climateControl.active = active;
  }

  handleScheduleToggle(active: boolean, id: number): void {
    this.climateSchedules[id].active = active;
  }

  getScheduleTitle(index: number): string {
    return 'Zeitplan ' + (index + 1);
  }

  createActiveScheduleText(id: number): string {
    const climateSchedule = this.climateSchedules[id];
    if (!climateSchedule.active) {
      return '';
    }
    return 'Das Auto wird an den ausgewählten Tagen um '
      + climateSchedule.startTime.toString()
      + ' Uhr \n auf ' + this.climateControl.targetTemperature.display(this.temperatureUnit)
      + ' temperiert.';
  }

  createActiveClimateControlText(): string {
    if (!this.climateControl.active ||
      this.climateControl.targetTemperature.value === this.climateControl.insideTemperature.value) {
      return '';
    }
    const targetTemperatureText = 'Das Auto wird gerade auf ' + this.climateControl.targetTemperature.display(this.temperatureUnit);
    if (this.climateControl.insideTemperature.value < this.climateControl.targetTemperature.value) {
      return targetTemperatureText + ' geheizt';
    }
    return targetTemperatureText + ' gekühlt';
  }
}
