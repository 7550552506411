import { OnInit } from '@angular/core';
import { EventEmitter, Input, Output } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-quick-action',
  templateUrl: './quick-action.component.html',
  styleUrls: ['./quick-action.component.css']
})
export class QuickActionComponent implements OnInit {
  @Input() icon: string;
  @Input() name: string;
  @Output() clicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  handleClick(): void {
    this.clicked.emit();
  }
}
