import { Theme } from './symbols';

export const greenTheme: Theme = {
  name: 'green',
  properties: {
    '--text': '#FFF',
    '--background': '#316B6F',
    '--box-background': '#467a7E',
    '--title-background': '#303B53',
    '--text-hover': '#303B53',
    '--box-hover': '#596275',
    '--title-hover': '#467a7E',
  }
};
