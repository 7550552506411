<div class="rounded message-box">
  <div class="p-2 message-header d-flex flex-column justify-content-center">
    <div class="align-self-center">INFOBOX</div>
  </div>
  <hr>

  <ng-container *ngIf="hasMessages(); then messages_template; else no_messages_template"></ng-container>

  <ng-template #messages_template>
    <app-message-box-item [messages]="messagesFromType(messageType.error)"></app-message-box-item>
    <hr *ngIf="hasMessagesFromType(messageType.error) && hasMessagesNotFromType(messageType.error)">
    <app-message-box-item [messages]="messagesFromType(messageType.warning)"></app-message-box-item>
    <hr *ngIf="hasMessagesFromType(messageType.warning) && hasMessagesFromType(messageType.info)">
    <app-message-box-item [messages]="messagesFromType(messageType.info)"></app-message-box-item>
  </ng-template>

  <ng-template #no_messages_template>
    <div class="ok p-2 d-flex justify-content-center">
      Es gibt keine Informationen
    </div>
  </ng-template>

</div>
