import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-climate-box',
  templateUrl: './climate-box.component.html',
  styleUrls: ['./climate-box.component.css']
})
export class ClimateBoxComponent implements OnInit {

  @Input() title: string;
  @Input() slideEnabled: boolean;
  @Output() slideOutput = new EventEmitter<boolean>();
  @Input() component: Component;
  @Input() text: string;

  constructor() { }

  ngOnInit(): void {
  }

  hasText(): boolean {
    return this.text.length !== 0;
  }

  handleSlideOutput(enabled: boolean): void {
    this.slideOutput.emit(enabled);
  }
}
