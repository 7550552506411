import { Component, Input, OnInit } from '@angular/core';
import { FuelStation } from 'src/app/models/fuel-station/fuel-station';

@Component({
  selector: 'app-fuel-finder',
  templateUrl: './fuel-finder.component.html',
  styleUrls: ['./fuel-finder.component.css']
})
export class FuelFinderComponent implements OnInit {

  selectedFuelStation: FuelStation;

  priceLevel = PriceLevel;

  @Input() fuelStations: FuelStation[];

  constructor() { }

  ngOnInit(): void {
    this.selectedFuelStation = this.fuelStations[0];
  }

  getFuelStationNames(): string[] {
    return this.fuelStations.map(fs => fs.name + ' (' + fs.distanceInKm.toFixed(2) + ' km, ' + fs.priceStr + ')');
  }

  handleIndex(index: number): void {
    this.selectedFuelStation = this.fuelStations[index];
  }

  computePriceLevel(): PriceLevel {
    if (this.selectedFuelStation.price <= 1.16) {
      return PriceLevel.low;
    }
    if (this.selectedFuelStation.price <= 1.32) {
      return PriceLevel.middle;
    }
    return PriceLevel.high;
  }
}

export enum PriceLevel {
  low, middle, high
}
