<app-bounding-box [component]="body_template" [settingsLink]="settingsLink()" [items]="getCarNames()"
  [selectedItemIndex]="getIndex()" (selectedItemIndexEvent)="handleDropdownIndex($event)"></app-bounding-box>

<ng-template #body_template>
  <div class="h-100 d-flex flex-column justify-content-between">
    <ng-container *ngTemplateOutlet="navigations_template"></ng-container>
    <ng-container *ngTemplateOutlet="infobox_template"></ng-container>
    <ng-container *ngTemplateOutlet="quick_actions_template"></ng-container>
  </div>
</ng-template>

<ng-template #navigations_template>
  <div class="mb-3 d-flex justify-content-between">
    <app-navigation [icon]="'lock_closed'" [name]="'Verriegelung'" [destination]="'locking'" [badge]="!isShutdown()">
    </app-navigation>
    <app-navigation [icon]="'thermometer_on'" [name]="'Temperatur'" [destination]="'climate'"
      [badge]="isFreezeAndNoClimateControl()"></app-navigation>
    <app-navigation [icon]="'fuel_station'" [name]="'Tanken'" [destination]="'fuel'" [badge]="isFuelLevelLow()">
    </app-navigation>
  </div>
  <div class="mb-3 d-flex justify-content-between">
    <app-navigation [icon]="'magnifier'" [name]="'Diagnose'" [destination]="'diagnosis'" [badge]="hasDiagnosisItems()">
    </app-navigation>
  </div>
</ng-template>

<ng-template #infobox_template>
  <app-message-box class="my-3 mb-auto" [messages]="messages()"></app-message-box>
</ng-template>

<ng-template #quick_actions_template>
  <div class="d-flex justify-content-around">
    <app-quick-action [icon]="getLockIcon()" [name]="getLockText()" (clicked)="handleLock()"></app-quick-action>
    <app-quick-action [icon]="getClimateIcon()" [name]="getClimateText()" (clicked)="handleClimate()">
    </app-quick-action>
  </div>
</ng-template>

<ngb-alert type="danger" *ngIf="alertText.length > 0" (closed)="closeAlert()">
  {{alertText}}</ngb-alert>
  