import { Component, Input, OnInit } from '@angular/core';
import { ClimateSchedule } from 'src/app/models/profile/climate-schedule';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  @Input() schedule: ClimateSchedule;

  constructor() { }

  ngOnInit(): void { }
}
