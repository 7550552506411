import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.css']
})
export class SlideToggleComponent implements OnInit {
  public id = this.createRandomId();

  @Input() enabled: boolean;
  @Output() output = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void { }

  passStatusToParent(): void {
    this.output.emit(this.enabled);
  }

  private createRandomId(): string {
    return '' + Math.floor(Math.random() * 1000000);
  }
}
