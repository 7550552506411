import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FuelSystem } from 'src/app/models/car/powertrain/fuel-system';
import { FuelStation } from 'src/app/models/fuel-station/fuel-station';
import { CarService } from 'src/app/service/car/car.service';
import { FuelStationService } from 'src/app/service/fuel-station/fuel-station.service';
import { ProfileService } from 'src/app/service/profile/profile.service';

@Component({
  selector: 'app-fuel',
  templateUrl: './fuel.component.html',
  styleUrls: ['./fuel.component.css']
})
export class FuelComponent implements OnInit {
  fuelSystem: FuelSystem;
  fuelStations: FuelStation[];
  private carId: number;

  rangeWarningInKm: number;

  constructor(
    private route: ActivatedRoute,
    private carService: CarService,
    private profileService: ProfileService,
    private fuelStationService: FuelStationService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.carId = +params.get('carId');
    });
    this.fuelStations = this.fuelStationService.fuelStations;
    this.fuelSystem = this.carService.getById(this.carId).fuelSystem;
    this.rangeWarningInKm = this.profileService.getByCarId(this.carId).rangeWarningInKm;
  }

  backLink(): string {
    return '/cars/' + this.carId;
  }

  settingsLink(): string {
    return '/cars/' + this.carId + '/settings';
  }
}
