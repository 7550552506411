import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Car } from 'src/app/models/car/car';
import { Profile } from 'src/app/models/profile/profile';
import { RepairStation } from 'src/app/models/repair-station/repair-station';
import { CarService } from 'src/app/service/car/car.service';
import { ProfileService } from 'src/app/service/profile/profile.service';

@Component({
  selector: 'app-repair',
  templateUrl: './repair.component.html',
  styleUrls: [
    './repair.component.css'
  ]
})
export class RepairComponent implements OnInit {
  private carId: number;

  selectedProfile: Profile;
  selectedCar: Car;
  alertText = '';
  selectedRepairStation: RepairStation;

  constructor(
    private route: ActivatedRoute,
    private carService: CarService,
    private profileService: ProfileService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.carId = +params.get('carId');
    });
    this.selectedCar = this.carService.getById(this.carId);
    this.selectedProfile = this.profileService.getByCarId(this.carId);
  }

  backLink(): string {
    return '/cars/' + this.carId + '/diagnosis';
  }

  settingsLink(): string {
    return '/cars/' + this.carId + '/settings';
  }

  handleSelectedRepairStation(repairStation: RepairStation): void {
    this.selectedRepairStation = repairStation;
  }

  setAlertStatus(): void {
    this.alertText = 'Der Diagnose-Bericht wurde an die Werkstatt gesendet. Ein Mitarbeiter wird sich zeitnah mit Ihnen in Verbindung setzen.';
  }

  closeAlert(): void {
    this.alertText = '';
  }
}
