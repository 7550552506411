<app-bounding-box [component]="body_template" [title]="'Diagnose'" [settingsLink]="settingsLink()"
  [backLink]="backLink()"></app-bounding-box>

<ng-template #body_template>
  <ng-container *ngIf="hasError(); then error_template; else no_error_template"></ng-container>
</ng-template>

<ng-template #error_template>
  <div class="d-flex flex-column my-error">
    <ng-container *ngTemplateOutlet="error_title_template"></ng-container>
    <div class="overflow-auto scrollbar d-flex flex-column justify-content-top">
      <ng-container *ngTemplateOutlet="diagnosis_items_template"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="quick_action_template"></ng-container>
  </div>
</ng-template>

<ng-template #error_title_template>
  <div class="pb-3 d-flex justify-content-between">
    <div class="title align-self-center">
      <div *ngIf="hasFatalError(); else warning_template" class="error">
        Fehler gefunden
      </div>
    </div>
    <ng-container *ngTemplateOutlet="last_check_template"></ng-container>
  </div>
</ng-template>

<ng-template #warning_template>
  <div class="warning">
    Warnung gefunden
  </div>
</ng-template>

<ng-template #diagnosis_items_template>
  <hr class="first-hr">
  <div *ngFor="let item of computeDiagnosisItems(); let last = last">
    <app-diagnosis-item [diagnosisItem]="item"></app-diagnosis-item>
    <hr *ngIf="!last">
  </div>
  <hr class="last-hr">
</ng-template>

<ng-template #quick_action_template>
  <div class="align-self-center mt-auto pt-2">
    <app-quick-action [icon]="'repair_station'" [name]="'Hilfe holen'" (clicked)="navigateToRepair()">
    </app-quick-action>
  </div>
</ng-template>

<ng-template #no_error_template>
  <div class="h-100 d-flex flex-column">
    <div class="pr-2 d-flex justify-content-end">
      <ng-container *ngTemplateOutlet="last_check_template"></ng-container>
    </div>
    <div class="ok h-100 d-flex flex-column justify-content-center">
      <mat-icon class="ok-img" svgIcon="thumb_up"></mat-icon>
      <div class="ok-size align-self-center">
        Alles gut
      </div>
      <div class="align-self-center">
        Es wurden keine Fehler festgestellt
      </div>
    </div>
  </div>
</ng-template>

<ng-template #last_check_template>
  <div class="last-check">
    Zuletzt aktualisiert: {{diagnosis.lastCheck}}
  </div>
</ng-template>