import { DiagnosisItem } from '../diagnosis/diagnosis-item';
import { DiagnosisType } from '../diagnosis/diagnosis-type';

export class Obd {
    constructor(
        public diagnosisItems: DiagnosisItem[],
    ) { }

    removeDiagnosisItem(errorCode: string): void {
        const index = this.diagnosisItems.findIndex(item => item.errorCode === errorCode);
        if (index !== -1) {
            this.diagnosisItems.splice(index, 1);
        }
    }

    createLambdaFailure(): void {
        this.diagnosisItems.push(new DiagnosisItem(
            'Lambdasonde defekt',
            'Beheizte Lambdasonde 2, Zylinderreihe 1 - Spannung zu niedrig.',
            'P0137',
            DiagnosisType.error));
    }

    createCatalystFailure(): void {
        this.diagnosisItems.push(new DiagnosisItem(
            'Katalysator defekt',
            'Katalysatoranlage, Zylinderreihe 1 - Wirkungsgrad unter Schwellenwert.',
            'P0420',
            DiagnosisType.error));
    }
}
