<div class="w-100 d-flex titlebar">
  <div class="w-25 align-self-center">
    <a *ngIf="showBack()" class="btn mt-1 arrow chevron left" [routerLink]="backLink"></a>
  </div>
  <div class="w-50 d-flex justify-content-center align-self-center">
    <ng-container *ngIf="showDropdown(); then dropdown_template; else title_template"></ng-container>
  </div>
  <div *ngIf="showSettings()" class="w-25 d-flex justify-content-end align-self-center">
    <a class="btn" [routerLink]="settingsLink">
      <mat-icon svgIcon="settings" class="settings-icon"></mat-icon>
    </a>
  </div>
</div>

<ng-template #dropdown_template>
  <app-dropdown [items]="dropdownItems" (index)="emitIndex($event)" [selectedIndex]="selectedItemIndex"></app-dropdown>
</ng-template>

<ng-template #title_template>
  <span>{{title}}</span>
</ng-template>
