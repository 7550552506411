import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BoundingBoxComponent } from './bounding-box/bounding-box.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { NavigationComponent } from './navigation/navigation.component';
import { QuickActionComponent } from './quick-action/quick-action.component';
import { SearchComponent } from './search/search.component';
import { SlideToggleComponent } from './slide-toggle/slide-toggle.component';
import { SuccessButtonComponent } from './success-button/success-button.component';
import { TitlebarComponent } from './titlebar/titlebar.component';

@NgModule({
    declarations: [
        BoundingBoxComponent,
        DropdownComponent,
        NavigationComponent,
        QuickActionComponent,
        SearchComponent,
        SlideToggleComponent,
        SuccessButtonComponent,
        TitlebarComponent,
    ],
    imports: [
        AppRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        NgxMatSelectSearchModule,

        NgbModule,
    ],
    exports: [
        AppRoutingModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        NgxMatSelectSearchModule,

        NgbModule,

        BoundingBoxComponent,
        DropdownComponent,
        NavigationComponent,
        QuickActionComponent,
        SearchComponent,
        SlideToggleComponent,
        SuccessButtonComponent,
    ]
})
export class GenericComponentsModule { }
