<app-bounding-box [component]="body_template" [title]="'Temperatur'" [backLink]="backLink()"
  [settingsLink]="settingsLink()">
</app-bounding-box>

<ng-template #body_template>
  <app-climate-box [title]="'Soll-Temperatur'" [slideEnabled]="climateControl.active"
    (slideOutput)="handleTargetTemperatureToggle($event)" [component]="target_temperature_template"
    [text]="createActiveClimateControlText()"> </app-climate-box>
  <hr>
  <ng-container *ngTemplateOutlet="timeschedule_template; context: {index: 0}"></ng-container>
  <hr>
  <ng-container *ngTemplateOutlet="timeschedule_template; context: {index: 1}"></ng-container>
</ng-template>

<ng-template #target_temperature_template>
  <app-target-temperature class="flex-fill" [climateControl]="climateControl" [outsideTemperature]="outsideTemperature"
    [temperatureUnit]="temperatureUnit"></app-target-temperature>
</ng-template>

<ng-template #timeschedule_template let-index='index'>
  <app-climate-box [title]="getScheduleTitle(index)" [slideEnabled]="climateSchedules[index].active"
    (slideOutput)="handleScheduleToggle($event, index)" [component]="schedule_component_template"
    [text]="createActiveScheduleText(index)"> </app-climate-box>

  <ng-template #schedule_component_template>
    <app-schedule class="flex-fill" [schedule]="climateSchedules[index]"></app-schedule>
  </ng-template>
</ng-template>