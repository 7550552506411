import { Temperature } from '../../weather/temperature';

export class ClimateControl {
    public active = false;
    constructor(
        public targetTemperature: Temperature,
        public insideTemperature: Temperature,
    ) { }

    addToTargetTemperature(value: number): void {
        this.targetTemperature.value = this.targetTemperature.value + value;
    }
}
