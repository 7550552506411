import { Injectable } from '@angular/core';
import { FuelStation } from 'src/app/models/fuel-station/fuel-station';

@Injectable({
  providedIn: 'root'
})
export class FuelStationService {
  private static UrlMock = new URL('https://goo.gl/maps/CpJxzeUUa9TBF1KC6');

  readonly fuelStations: FuelStation[];

  constructor() {
    this.fuelStations = this.createMockFuelStations();
  }

  createMockFuelStations(): FuelStation[] {
    const fuelStations: FuelStation[] = [];

    fuelStations.push(new FuelStation('JET', '01727593754',
      'Bad Canstatt', '70372', 'Hauptstraße', '1',
      0.41, FuelStationService.UrlMock));
    fuelStations.push(new FuelStation('Aral', '01729474638',
      'Feuerbach', '70191', 'Schulstraße', '23',
      0.721, FuelStationService.UrlMock));
    fuelStations.push(new FuelStation('Esso', '01725644204',
      'Vaihingen ', '71665', 'Gartenstraße', '12b',
      0.89, FuelStationService.UrlMock));
    fuelStations.push(new FuelStation('Agip', '017194874637',
      'Zuffenhausen ', '70435', 'Dorfstraße', '27-29',
      1.1, FuelStationService.UrlMock));
    fuelStations.push(new FuelStation('T-Tankstelle', '017174563830',
      'Zuffenhausen ', '70435', 'Bahnhofstraße', '102',
      1.48, FuelStationService.UrlMock));
    fuelStations.push(new FuelStation('OMV', '017185473638',
      'Feuerbach', '70191', 'Bergstraße', '41',
      3.972, FuelStationService.UrlMock));
    fuelStations.push(new FuelStation('BFT-Tankstelle', '01769404875',
      'Ludwigsburg', '71634', 'Birkenweg', '8',
      4.801, FuelStationService.UrlMock));
    fuelStations.push(new FuelStation('Freie Tankstelle', '017674628273',
      'Leonberg', '71067', 'Lindenstraße', '21',
      9.824, FuelStationService.UrlMock));
    fuelStations.push(new FuelStation('Star Tankstelle', '017684725413',
      'Backnang', '71522', 'Kirchstraße', '57',
      12.820, FuelStationService.UrlMock));

    return fuelStations;
  }
}
