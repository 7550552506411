<div class="d-flex my-mat p-2 m-auto">
  <mat-form-field class="w-100">
    <mat-select placeholder="{{name}}" [formControl]="itemControl" #singleSelect
      (selectionChange)="passIndexToParent($event.value)">
      <mat-option>
        <ngx-mat-select-search [formControl]="filterControl" ngDefaultControl></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let item of filteredItems | async;" [value]="item">
        {{item}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
