<div class="title mb-3">
    Tankstellenfinder
</div>

<div class="mb-3 first-content">
    <app-search [items]="getFuelStationNames()" [selectedIndex]="0" [name]="'Suche'" (index)="handleIndex($event)">
    </app-search>
</div>

<div class="second-content">
    <div class="d-flex mb-1">
        <span class="left-row">Name</span>
        <span>{{selectedFuelStation.name}}</span>
    </div>

    <div class="d-flex mb-1">
        <span class="left-row">Straße</span>
        <span>{{selectedFuelStation.street}} {{selectedFuelStation.streetAppendix}}</span>
    </div>

    <div class="d-flex mb-1">
        <span class="left-row">Stadt</span>
        <span>{{selectedFuelStation.plz}} {{selectedFuelStation.city}}</span>
    </div>

    <div class="d-flex mb-1">
        <span class="left-row">Entfernung</span>
        <span>{{+selectedFuelStation.distanceInKm.toFixed(2)}} km</span>
    </div>

    <div class="d-flex mb-1">
        <span class="left-row">Preis</span>
        <span [class.ok]="computePriceLevel() === priceLevel.low"
            [class.warning]="computePriceLevel() === priceLevel.middle"
            [class.error]="computePriceLevel() === priceLevel.high">{{selectedFuelStation.priceStr}}</span>
    </div>

    <div class="d-flex flex-column justify-content-center">
        <div class="mt-1 d-flex">
            <span class="align-self-center left-row">Navigation</span>
            <span>
                <a [href]=selectedFuelStation.url.toString() target="_blank">
                    <mat-icon class="gps" svgIcon="gps"></mat-icon>
                </a>
            </span>
        </div>
    </div>
</div>