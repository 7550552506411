import { DiagnosisItem } from '../car/diagnosis/diagnosis-item';
import { DiagnosisType } from '../car/diagnosis/diagnosis-type';
import { MessageItem } from './message-item';
import { MessageType } from './message-type';

export class Messages {
    constructor(
        private diagnosisItems: DiagnosisItem[],
        private isLowFuelLevel: boolean,
        private isFreeze: boolean
    ) { }

    computeItems(): MessageItem[] {
        return this.diagnosisMessages().concat(
            this.fuelMessages(),
            this.freezeMessages());
    }

    diagnosisMessages(): MessageItem[] {
        const diagnosisMessages = [];
        this.diagnosisItems.forEach(item => {
            const type = item.type === DiagnosisType.error ? MessageType.error : MessageType.warning;
            diagnosisMessages.push(new MessageItem(item.title, 'diagnosis', type));
        });
        return diagnosisMessages;
    }

    fuelMessages(): MessageItem[] {
        return this.isLowFuelLevel ? [new MessageItem('Bitte tanken', 'fuel', MessageType.warning)] : [];
    }

    freezeMessages(): MessageItem[] {
        return this.isFreeze ? [new MessageItem('Frost vorhergesagt', 'climate', MessageType.info)] : [];
    }
}
