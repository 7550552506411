import { Component, Input, OnInit } from '@angular/core';
import { Profile } from 'src/app/models/profile/profile';

@Component({
  selector: 'app-report-personal-data',
  templateUrl: './report-personal-data.component.html',
  styleUrls: ['./report-personal-data.component.css']
})
export class ReportPersonalDataComponent implements OnInit {
  @Input() profile: Profile;

  constructor() { }

  ngOnInit(): void { }
}
