import { Injectable } from '@angular/core';
import { ClimateSchedule } from 'src/app/models/profile/climate-schedule';
import { Profile } from 'src/app/models/profile/profile';
import { TemperatureUnit } from 'src/app/models/weather/temperature-unit';
import { CarService } from '../car/car.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private readonly profiles: Profile[];

  constructor(
    carService: CarService
  ) {
    this.profiles = this.createMockProfiles(carService);
  }

  private createMockProfiles(carService: CarService): Profile[] {
    const profiles: Profile[] = [];

    carService.cars.forEach(car => {
      profiles.push(new Profile(
        car.name,
        '0711 12345',
        0,
        70,
        1.5,
        TemperatureUnit.C,
        'dark',
        [new ClimateSchedule(0), new ClimateSchedule(1)],
        car.id
      ));
    });

    return profiles;
  }

  getByCarId(carId: number): Profile {
    return this.profiles.find(profile => profile.carId === carId);
  }
}
