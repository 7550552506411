import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FailureComponent } from './components/failure/failure.component';
import { ThemeModule } from './theme/theme.module';
import { darkTheme } from './theme/dark-theme';
import { greenTheme } from './theme/green-theme';
import { ComponentsModule } from './components/components.module';

@NgModule({
  declarations: [
    AppComponent,
    FailureComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    ThemeModule.forRoot({
      themes: [darkTheme, greenTheme],
      active: 'dark'
    }),
    ComponentsModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
