export class FuelSystem {

    constructor(
        public currentTankLevelInLiters: number,
        readonly fuelType: string,
        readonly tankCapacityInLiters: number,
        readonly averageConsumptionInLitersPer100Km: number,
    ) { }

    getTankLevelInPercent(): number {
        return this.currentTankLevelInLiters / this.tankCapacityInLiters * 100;
    }

    getRemainingRangeInMeters(): number {
        return (this.currentTankLevelInLiters / this.averageConsumptionInLitersPer100Km) * 100000;
    }

    getRemainingRangeInKm(): number {
        return this.getRemainingRangeInMeters() / 1000;
    }
}
