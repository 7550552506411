import { Component, Input, OnInit } from '@angular/core';
import { Profile } from 'src/app/models/profile/profile';

@Component({
  selector: 'app-warnings',
  templateUrl: './warnings.component.html',
  styleUrls: ['./warnings.component.css']
})
export class WarningsComponent implements OnInit {
  @Input() profile: Profile;

  constructor() { }

  ngOnInit(): void { }

  handleRangeWarningIncrement(): void {
    this.profile.addToRangeWarningInKm(10);
  }

  handleRangeWarningDecrement(): void {
    this.profile.addToRangeWarningInKm(-10);
  }

  handlePressureWarningIncrement(): void {
    this.profile.addToWheelPressureWarningInBar(0.1);
  }

  handlePressureWarningDecrement(): void {
    this.profile.addToWheelPressureWarningInBar(-0.1);
  }
}
