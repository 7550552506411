import { DiagnosisType } from '../diagnosis/diagnosis-type';
import { DiagnosisItem } from '../diagnosis/diagnosis-item';
import { ChassisPosition } from './chassis-position';

export class Wheel {
    warning: DiagnosisItem;

    constructor(
        readonly chassisPosition: ChassisPosition,
        public pressureInBar: number,
    ) {
        this.createWarning();
    }

    updatePressure(pressure: number): void {
        this.pressureInBar = pressure;
        this.createWarning();
    }

    private createWarning(): void {
        this.warning = new DiagnosisItem(
            'Reifendruck niedrig',
            'Der aktuelle Reifendruck ' + this.chassisPosition + ' beträgt ' + +this.pressureInBar.toFixed(2) + ' bar und ' +
            'ist damit niedriger als der minimale Reifendruck. ' +
            'Ändern Sie den minimalen Reifendruck in den Einstellungen oder pumpen Sie den Reifen auf.',
            'B2868', DiagnosisType.warning
        );
    }
}
