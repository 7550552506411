export class ClimateSchedule {
    id: number;
    readonly daySchedules: WeekDaySchedule[] = [
        new WeekDaySchedule(WeekDay.Monday, true),
        new WeekDaySchedule(WeekDay.Tuesday, true),
        new WeekDaySchedule(WeekDay.Wednesday, true),
        new WeekDaySchedule(WeekDay.Thursday, true),
        new WeekDaySchedule(WeekDay.Friday, true),
        new WeekDaySchedule(WeekDay.Saturday, false),
        new WeekDaySchedule(WeekDay.Sunday, false)
    ];
    public startTime = '08:00';
    public active = false;

    constructor(id: number) {
        this.id = id;
    }
}

export enum WeekDay {
    Monday = 'Montag',
    Tuesday = 'Dienstag',
    Wednesday = 'Mittwoch',
    Thursday = 'Donnerstag',
    Friday = 'Freitag',
    Saturday = 'Samstag',
    Sunday = 'Sonntag'
}

export class WeekDaySchedule {
    constructor(
        readonly weekDay: WeekDay,
        public active: boolean
    ) { }
}
