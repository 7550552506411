<app-bounding-box [component]="body_template" [title]="'Werkstatt'" [settingsLink]="settingsLink()"
  [backLink]="backLink()">
</app-bounding-box>

<ng-template #body_template>
  <app-report-personal-data [profile]="selectedProfile"></app-report-personal-data>
  <hr>
  <app-search-repair [favoriteRepairStationId]="selectedProfile.favoriteWorkshopId"
    (selected)="handleSelectedRepairStation($event)"></app-search-repair>
  <hr>
  <app-send-report [diagnosis]="selectedCar.diagnosis"
    [wheelPressureWarning]="selectedProfile.wheelPressureWarningInBar" (sent)="setAlertStatus()">
  </app-send-report>
</ng-template>

<ngb-alert type="info" *ngIf="alertText.length > 0" (closed)="closeAlert()">
  {{alertText}}</ngb-alert>