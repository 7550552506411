<div class="h-100 d-flex flex-column justify-content-center">
    <div class="d-flex flex-column box">
        <app-success-button [success]="!fuelSystemFailure" [icon]="'fuel_station'" (clicked)="handleFuelSystem()">
        </app-success-button>
        <app-success-button class="mt-3" [success]="!windowFailure" [icon]="'window_open'" (clicked)="handleWindows()">
        </app-success-button>
        <app-success-button class="mt-3" [success]="!wheelFailure" [icon]="'tire'" (clicked)="handleWheels()">
        </app-success-button>
        <app-success-button class="mt-3" [success]="!lightFailure" [icon]="'light'" (clicked)="handleLights()">
        </app-success-button>
        <app-success-button class="mt-3" [success]="!lambdaFailure" [icon]="'lambda'" (clicked)="handleLambda()">
        </app-success-button>
        <app-success-button class="mt-3" [success]="!catalystFailure" [icon]="'catalyst'" (clicked)="handleCatalyst()">
        </app-success-button>
        <app-success-button class="mt-3" [success]="!weather.isFreeze" [icon]="'snowflake'" (clicked)="toggleFreeze()">
        </app-success-button>
    </div>
</div>
