import { Injectable } from '@angular/core';
import { RepairStation } from 'src/app/models/repair-station/repair-station';

@Injectable({
  providedIn: 'root'
})
export class RepairStationService {
  repairStations: RepairStation[] = [];

  constructor() {
    this.repairStations = this.createMockWorkshops();
  }

  private createMockWorkshops(): RepairStation[] {
    const workshops: RepairStation[] = [];
    workshops.push(new RepairStation(1, 'KFZ Technik Mayer', '01727593754', 'Bad Canstatt', '70372', 'Hauptstraße', '1', 410));
    workshops.push(new RepairStation(2, 'Automobiltechnik GmbH', '01729474638', 'Feuerbach', '70191', 'Schulstraße', '23', 721));
    workshops.push(new RepairStation(3, 'Schmidt Kfz Werkstatt', '01725644204', 'Vaihingen ', '71665', 'Gartenstraße', '12b', 890));
    workshops.push(new RepairStation(4, 'Auto Service Fischer', '017194874637', 'Zuffenhausen ', '70435', 'Dorfstraße', '27-29', 1100));
    workshops.push(new RepairStation(5, 'EUROMASTER GmbH', '017174563830', 'Zuffenhausen ', '70435', 'Bahnhofstraße', '102', 1480));
    workshops.push(new RepairStation(6, 'A.T.U. Stuttgart', '017185473638', 'Feuerbach', '70191', 'Bergstraße', '41', 3972));
    workshops.push(new RepairStation(7, 'pitstop', '01769404875', 'Ludwigsburg', '71634', 'Birkenweg', '8', 4801));
    workshops.push(new RepairStation(8, 'point S Weber GmbH & Co KG', '017674628273', 'Leonberg', '71067', 'Lindenstraße', '21', 9824));
    workshops.push(new RepairStation(9, 'Autofit Kfz-Meisterwerkstatt', '017684725413', 'Backnang', '71522', 'Kirchstraße', '57', 12820));
    return workshops;
  }

  getMockWorkshops(): RepairStation[] {
    return this.repairStations;
  }

  getRepairStationById(id: number): RepairStation {
    return this.repairStations.find(station => station.id === id);
  }
}
