import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Diagnosis } from 'src/app/models/car/diagnosis/diagnosis';
import { DiagnosisItem } from 'src/app/models/car/diagnosis/diagnosis-item';
import { DiagnosisType } from 'src/app/models/car/diagnosis/diagnosis-type';

@Component({
  selector: 'app-send-report',
  templateUrl: './send-report.component.html',
  styleUrls: ['./send-report.component.css']
})
export class SendReportComponent implements OnInit {
  @Input() diagnosis: Diagnosis;
  @Input() wheelPressureWarning: number;
  @Output() sent = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  countDiagnosisErrors(): number {
    return this.computeDiagnosisItemsOfType(DiagnosisType.error);
  }

  countDiagnosisWarnings(): number {
    return this.computeDiagnosisItemsOfType(DiagnosisType.warning);
  }

  computeDiagnosisItemsOfType(diagnosisType: DiagnosisType): number {
    return this.diagnosis.computeDiagnosisItems(this.wheelPressureWarning).filter(item => item.type === diagnosisType).length;
  }

  handleSendButtonClicked(): void {
    this.sent.emit();
  }
}
