import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-titlebar',
  templateUrl: './titlebar.component.html',
  styleUrls: ['./titlebar.component.css']
})
export class TitlebarComponent implements OnInit {
  @Input() title: string;
  @Input() backLink: string;
  @Input() settingsLink: string;
  @Input() dropdownItems: string[];
  @Input() selectedItemIndex: number;
  @Output() selectedItemIndexEvent = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void { }

  showDropdown(): boolean {
    return this.dropdownItems !== undefined;
  }

  showBack(): boolean {
    return this.backLink !== undefined;
  }

  showSettings(): boolean {
    return this.settingsLink !== undefined;
  }

  emitIndex(index: number): void {
    this.selectedItemIndexEvent.emit(index);
  }
}
