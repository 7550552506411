import { Component, Input, OnInit } from '@angular/core';
import { ClimateControl } from 'src/app/models/car/cabin/climate-control';
import { Temperature } from 'src/app/models/weather/temperature';
import { TemperatureUnit } from 'src/app/models/weather/temperature-unit';

@Component({
  selector: 'app-target-temperature',
  templateUrl: './target-temperature.component.html',
  styleUrls: ['./target-temperature.component.css']
})
export class TargetTemperatureComponent implements OnInit {
  @Input() climateControl: ClimateControl;
  @Input() outsideTemperature: Temperature;
  @Input() temperatureUnit: TemperatureUnit;

  ngOnInit(): void { }

  handlePlus(): void {
    this.climateControl.addToTargetTemperature(1);
  }

  handleMinus(): void {
    this.climateControl.addToTargetTemperature(-1);
  }
}
