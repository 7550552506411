<div class="d-flex flex-column">
  <div class="title">Bericht</div>
  <div class="d-flex flex-row first-content">
    <div class="my-width d-flex flex-column align-self-center">
      <div class="d-flex flex-row mb-1">
        <span class="left-row">Datum</span>
        <span>{{diagnosis.lastCheck}}</span>
      </div>
      <div class="d-flex flex-row mb-1">
        <span class="left-row">Fehler</span>
        <span>{{countDiagnosisErrors()}}</span>
      </div>
      <div class="d-flex flex-row mb-1">
        <span class="left-row">Warnungen</span>
        <span>{{countDiagnosisWarnings()}}</span>
      </div>
    </div>
    <app-quick-action class="w-25" icon="send" name="Fehlerbericht senden" (clicked)="handleSendButtonClicked()">
    </app-quick-action>
  </div>
  <div class="first-content mt-4 infotext">
    Senden Sie den Fehlerbericht direkt an eine unserer Partnerwerkstätten und ein Mitarbeiter setzt sich zeitnah mit
    Ihnen in Verbindung.
  </div>
</div>