<div class="button-size">
  <a [routerLink]="destination">
    <div *ngIf="badge" class="d-flex justify-content-end">
      <mat-icon class="mt-2 badge" svgIcon="exclamation-mark"></mat-icon>
    </div>
    <div class="rounded button-type">
      <div class="mb-2 d-flex justify-content-center">
        <mat-icon svgIcon="{{icon}}"></mat-icon>
      </div>
      <div class="d-flex justify-content-center bold">{{name}}</div>
    </div>
  </a>
</div>
