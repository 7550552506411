import { Component, Input, OnInit } from '@angular/core';
import { Profile } from 'src/app/models/profile/profile';
import { RepairStation } from 'src/app/models/repair-station/repair-station';
import { RepairStationService } from 'src/app/service/repair-station/repair-station.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  @Input() profile: Profile;

  private repairStations: RepairStation[];
  indexOfFavoriteStation: number;

  constructor(
    private repairStationService: RepairStationService
  ) { }

  ngOnInit(): void {
    this.repairStations = this.repairStationService.getMockWorkshops();
    this.indexOfFavoriteStation = this.getIndexOfFavoriteRepairStation();
  }

  createDisplayNames(): string[] {
    return this.repairStations.map(station => station.displayWithDistance());
  }

  handleSearchIndex(index: number): void {
    this.profile.favoriteWorkshopId = this.repairStations[index].id;
  }

  getIndexOfFavoriteRepairStation(): number {
    const favoriteIndex = this.repairStations.findIndex(station => station.id === this.profile.favoriteWorkshopId);
    return favoriteIndex === -1 ? 0 : favoriteIndex;
  }
}
