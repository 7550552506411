import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-bounding-box',
  templateUrl: './bounding-box.component.html',
  styleUrls: ['./bounding-box.component.css']
})
export class BoundingBoxComponent implements OnInit {
  @Input() title: string;
  @Input() backLink: string;
  @Input() settingsLink: string;
  @Input() component: Component;
  @Input() items: string[];
  @Input() selectedItemIndex: number;
  @Output() selectedItemIndexEvent = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void { }

  handleDropdownIndex(index: number): void {
    this.selectedItemIndexEvent.emit(index);
  }
}
