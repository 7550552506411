<div class="title mb-3">
  Sonstiges
</div>

<div class="first-content">
  <div class="d-flex flex-column">
    <div class="d-flex flex-row align-items-center">
      <div class="w-50">
        Design
      </div>
      <div class="w-50">
        <app-dropdown [items]="themeNames" (index)="handleThemeIndex($event)" [selectedIndex]="selectedThemeIndex">
        </app-dropdown>
      </div>
    </div>
    
    <div class="d-flex flex-row align-items-center">
      <div class="w-50">
        Temperatureinheit
      </div>
      <div class="w-50">
        <app-dropdown [items]="temperatureUnits" (index)="handleTemperatureIndex($event)"
          [selectedIndex]="selectedTemperatureIndex"></app-dropdown>
      </div>
    </div>
  </div>
</div>
