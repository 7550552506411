<div class="d-flex flex-column">
  <div class="title">Persönliche Daten</div>
  <div class="first-content mb-2">
    <div class="my-mat p-2 mt-3">
      <mat-form-field class="w-50 pr-2">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="profile.name">
      </mat-form-field>
      <mat-form-field class="w-50">
        <mat-label>Telefonnummer</mat-label>
        <input matInput [(ngModel)]="profile.phone">
      </mat-form-field>
    </div>
  </div>
</div>