import { CarDoor } from './car-door';
import { CarWindow } from './car-window';

export class Cabin {
    constructor(
        readonly doors: CarDoor[],
        readonly windows: CarWindow[],
    ) { }

    getNumberOfRows(): number {
        return 2;
    }

    shutdown(): void {
        this.doors.forEach(door => door.lock());
        this.windows.forEach(window => window.oneShotClose());
    }

    isShutdown(): boolean {
        return this.areDoorsLocked() && this.areWindowsClosed();
    }

    areDoorsLocked(): boolean {
        return this.doors.every(door => door.isLocked);
    }

    areWindowsClosed(): boolean {
        return this.windows.every(window => window.isClosed);
    }

    unlockDoors(): void {
        this.doors.forEach(door => door.unlock());
    }
}
