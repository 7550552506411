import { Theme } from './symbols';

export const darkTheme: Theme = {
  name: 'dark',
  properties: {
    '--text': '#FFF',
    '--background': '#000',
    '--box-background': '#222',
    '--title-background': '#666',
    '--text-hover': '#666',
    '--box-hover': '#666',
    '--title-hover': '#222',
  }
};
