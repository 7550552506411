import { TemperatureUnit } from '../weather/temperature-unit';
import { ClimateSchedule } from './climate-schedule';

export class Profile {
    private static ID = 0;

    readonly id = Profile.ID++;

    constructor(
        public name: string = '',
        public phone: string = '',
        public favoriteWorkshopId: number,
        public rangeWarningInKm: number,
        public wheelPressureWarningInBar: number,
        public temperatureUnit: TemperatureUnit,
        public theme: string,
        readonly climateSchedules: ClimateSchedule[],
        readonly carId: number,
    ) { }

    static createMockProfile(): Profile {
        return new Profile('name', 'phone', 1, 70, 1.5, TemperatureUnit.C, 'green', [new ClimateSchedule(0), new ClimateSchedule(1)], 1);
    }

    addToRangeWarningInKm(value: number): void {
        this.rangeWarningInKm += value;
    }

    addToWheelPressureWarningInBar(value: number): void {
        this.wheelPressureWarningInBar += value;
    }
}
