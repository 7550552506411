<div class="d-flex" *ngIf="hasMessages()">
  <div class="message-type d-flex justify-content-center align-items-center"
    [class.error]="getType() === messageType.error" [class.warning]="getType() === messageType.warning"
    [class.info]="getType() === messageType.info">
    {{getType()}}
  </div>
  <div class="message-body">
    <div *ngFor="let message of messages; let last = last">
      <div class="p-2">
        <a [routerLink]="destinationLink(message)">
          <div class="pr-2 d-flex justify-content-between">
            <span>
              {{message.text}}
            </span>
            <span class="chevron right"></span>
          </div>
        </a>
      </div>
      <hr *ngIf="!last">
    </div>
  </div>
</div>
