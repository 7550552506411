import { Component, Input, OnInit } from '@angular/core';
import { Profile } from 'src/app/models/profile/profile';
import { TemperatureUnit } from 'src/app/models/weather/temperature-unit';
import { ThemeService } from 'src/app/theme/theme.service';

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.css']
})
export class OthersComponent implements OnInit {
  @Input() profile: Profile;

  themeNames: string[];
  selectedThemeIndex: number;
  temperatureUnits: string[] = Object.values(TemperatureUnit);
  selectedTemperatureIndex: number;

  constructor(
    private themeService: ThemeService
  ) { }

  ngOnInit(): void {
    this.themeNames = this.themeService.themes.map(theme => theme.name);
    this.selectedThemeIndex = this.themeService.themes
      .findIndex(theme => theme === this.themeService.getActiveTheme());
    this.selectedTemperatureIndex = Object.values(TemperatureUnit)
      .findIndex(temperatureUnit => temperatureUnit === this.profile.temperatureUnit);
  }

  handleThemeIndex(index: number): void {
    this.themeService.setTheme(this.themeNames[index]);
  }

  handleTemperatureIndex(index: number): void {
    this.selectedTemperatureIndex = index;
    this.profile.temperatureUnit = Object.values(TemperatureUnit)[index];
  }
}
