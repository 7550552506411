import { Component, Input, OnInit } from '@angular/core';
import { CarDoor } from 'src/app/models/car/cabin/car-door';

@Component({
  selector: 'app-door-button',
  templateUrl: './door-button.component.html',
  styleUrls: ['./door-button.component.css']
})
export class DoorButtonComponent implements OnInit {
  @Input() carDoor: CarDoor;

  constructor() { }

  ngOnInit(): void { }

  getIcon(): string {
    return this.carDoor.isLocked ? 'lock_closed' : 'lock_open';
  }

  handleClick(): void {
    this.carDoor.toggleStatus();
  }
}
