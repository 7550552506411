import { ActivatedRoute, Router } from '@angular/router';
import { OnInit, Component } from '@angular/core';
import { CarService } from 'src/app/service/car/car.service';
import { DiagnosisType } from 'src/app/models/car/diagnosis/diagnosis-type';
import { DiagnosisItem } from 'src/app/models/car/diagnosis/diagnosis-item';
import { ProfileService } from 'src/app/service/profile/profile.service';
import { Diagnosis } from 'src/app/models/car/diagnosis/diagnosis';

@Component({
  selector: 'app-diagnosis',
  templateUrl: './diagnosis.component.html',
  styleUrls: ['./diagnosis.component.css']
})
export class DiagnosisComponent implements OnInit {
  private carId: number;

  diagnosis: Diagnosis;
  wheelPressureWarningInBar: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private carService: CarService,
    private profileService: ProfileService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.carId = +params.get('carId');
    });
    this.wheelPressureWarningInBar = this.profileService.getByCarId(this.carId).wheelPressureWarningInBar;
    this.diagnosis = this.carService.getById(this.carId).diagnosis;
  }

  settingsLink(): string {
    return '/cars/' + this.carId + '/settings';
  }

  backLink(): string {
    return '/cars/' + this.carId;
  }

  computeDiagnosisItems(): DiagnosisItem[] {
    return this.diagnosis.computeDiagnosisItems(this.wheelPressureWarningInBar);
  }

  hasError(): boolean {
    return this.computeDiagnosisItems().length > 0;
  }

  hasFatalError(): boolean {
    return this.computeDiagnosisItems().some(diagnosisItem => diagnosisItem.type === DiagnosisType.error);
  }

  navigateToRepair(): void {
    this.router.navigateByUrl('/cars/' + this.carId + '/repair');
  }
}
