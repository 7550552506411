import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Car } from 'src/app/models/car/car';
import { DiagnosisItem } from 'src/app/models/car/diagnosis/diagnosis-item';
import { MessageItem } from 'src/app/models/messages/message-item';
import { Messages } from 'src/app/models/messages/messages';
import { Profile } from 'src/app/models/profile/profile';
import { Weather } from 'src/app/models/weather/weather';
import { CarService } from 'src/app/service/car/car.service';
import { ProfileService } from 'src/app/service/profile/profile.service';
import { WeatherService } from 'src/app/service/weather/weather.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  private selectedCar: Car;
  private selectedProfile: Profile;
  private carId: number;
  private weather: Weather;

  alertText = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private carService: CarService,
    private profileService: ProfileService,
    private weatherService: WeatherService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.carId = +params.get('carId');
    });
    this.selectedCar = this.carService.getById(this.carId);
    this.selectedProfile = this.profileService.getByCarId(this.carId);
    this.weather = this.weatherService.weather;
  }

  settingsLink(): string {
    return '/cars/' + this.carId + '/settings';
  }

  handleDropdownIndex(index: number): void {
    this.router.navigate(['/cars/' + this.carService.cars[index].id])
      .then(() => {
        window.location.reload();
      });
  }

  getIndex(): number {
    return this.getCarNames().indexOf(this.selectedCar.name);
  }

  getCarNames(): string[] {
    return this.carService.cars.map(car => car.name);
  }

  getLockIcon(): string {
    return this.isShutdown() ? 'lock_closed' : 'lock_open';
  }

  getClimateIcon(): string {
    return this.selectedCar.climateControl.active ? 'thermometer_on' : 'thermometer_off';
  }

  getLockText(): string {
    return this.isShutdown() ? 'Auto entriegeln' : 'Auto verriegeln';
  }

  getClimateText(): string {
    return this.selectedCar.climateControl.active ? 'Heizung ausschalten' : 'Heizung anschalten';
  }

  isShutdown(): boolean {
    return this.selectedCar.cabin.isShutdown();
  }

  isFreezeAndNoClimateControl(): boolean {
    return this.weather.isFreeze &&
      this.selectedProfile.climateSchedules.every(schedule => !schedule.active) &&
      !this.selectedCar.climateControl.active;
  }

  handleLock(): void {
    const cabin = this.selectedCar.cabin;
    if (this.isShutdown()) {
      cabin.unlockDoors();
    } else {
      cabin.shutdown();
      if (!cabin.areWindowsClosed()) {
        const cabinPosition = cabin.windows.find(window => window.isBroken).cabinPosition;
        this.setAlertStatus(cabinPosition);
      }
    }
  }

  handleClimate(): void {
    this.selectedCar.climateControl.active = !this.selectedCar.climateControl.active;
  }

  setAlertStatus(cabinPosition: string): void {
    this.alertText = 'Das Fenster ' + cabinPosition + ' ist kaputt.';
  }

  closeAlert(): void {
    this.alertText = '';
  }

  isFuelLevelLow(): boolean {
    return this.selectedCar.fuelSystem.getRemainingRangeInKm() <= this.selectedProfile.rangeWarningInKm;
  }

  hasDiagnosisItems(): boolean {
    return this.computeDiagnosisItems().length > 0;
  }

  computeDiagnosisItems(): DiagnosisItem[] {
    return this.selectedCar.diagnosis.computeDiagnosisItems(this.selectedProfile.wheelPressureWarningInBar);
  }

  messages(): MessageItem[] {
    const messages = new Messages(this.computeDiagnosisItems(), this.isFuelLevelLow(), this.weather.isFreeze);
    return messages.computeItems();
  }
}
