<div class="d-flex mb-3">
    <div class="title w-50">
        Tankinfo
    </div>
    <div class="w-50">
        <mat-icon svgIcon="fuel_station"></mat-icon>
    </div>
</div>

<div class="first-content">
    <div class="d-flex mb-1">
        <span class="w-50">Füllstand</span>
        <span>{{fuelSystem.getTankLevelInPercent()}}% ({{fuelSystem.currentTankLevelInLiters}} l)</span>
    </div>

    <div class="d-flex mb-1">
        <span class="w-50">Reichweite</span>
        <span [class.warning]="isFuelLow()">{{fuelSystem.getRemainingRangeInKm().toFixed(2)}} km</span>
    </div>
    <div class="d-flex mb-1">
        <span class="w-50">Reichweitenwarnung</span>
        <span>{{rangeWarningInKm}} km</span>
    </div>
    <div class="d-flex mb-1">
        <span class="w-50">Verbrauch</span>
        <span>{{fuelSystem.averageConsumptionInLitersPer100Km}} l/100km</span>
    </div>
    <div class="d-flex mb-1">
        <span class="w-50">Benzin</span>
        <span>{{fuelSystem.fuelType}}</span>
    </div>
</div>