<app-bounding-box [component]="body_template" [settingsLink]="settingsLink()" [title]="'Verriegelung'"
  [backLink]="backLink()"></app-bounding-box>

<ng-template class="h-100" #body_template>
  <div class="h-100 d-flex flex-column">
    <div class="h-100 mb-auto d-flex flex-column justify-content-center">
      <ng-container *ngTemplateOutlet="car_template"></ng-container>
    </div>
    <ng-container *ngTemplateOutlet="quick_action_template"></ng-container>
  </div>
</ng-template>

<ng-template #car_template>
  <table class="w-100">
    <tr>
      <td class="w-25">
        <ng-container *ngTemplateOutlet="buttons_template; context: {cabinPositionRef: cabinPosition.Row1Left}"></ng-container>
      <td class="w-50" [attr.rowspan]="cabin.getNumberOfRows()">
        <mat-icon class="car" svgIcon="car"></mat-icon>
      </td>
      <td class="w-25">
        <ng-container *ngTemplateOutlet="buttons_template; context: {cabinPositionRef: cabinPosition.Row1Right}"></ng-container>
      </td>
    </tr>
    <tr>
      <td>
        <ng-container *ngTemplateOutlet="buttons_template; context: {cabinPositionRef: cabinPosition.Row2Left}"></ng-container>
      </td>
      <td>
        <ng-container *ngTemplateOutlet="buttons_template; context: {cabinPositionRef: cabinPosition.Row2Right}"></ng-container>
      </td>
    </tr>
    <tr>
      <td class="w-25"></td>
      <td class="w-50 align-top">
        <div class="mt-2 d-flex justify-content-center">
          <app-door-button [carDoor]="getDoorOfPosition(cabinPosition.Trunk)"></app-door-button>
        </div>
      </td>
      <td class="w-25"></td>
    </tr>
  </table>
</ng-template>

<ng-template #buttons_template let-cabinPositionRef='cabinPositionRef'>
  <div class="mb-2 d-flex justify-content-center">
    <app-window-button [carWindow]="getWindowOfPosition(cabinPositionRef)"></app-window-button>
  </div>
  <div class="d-flex justify-content-center">
    <app-door-button [carDoor]="getDoorOfPosition(cabinPositionRef)"></app-door-button>
  </div>
</ng-template>

<ng-template #quick_action_template>
  <div class="d-flex justify-content-center">
    <div class="h-100 d-flex justify-content-center">
      <app-quick-action [icon]="getLockIcon()" [name]="getLockText()" (clicked)="handleLock()"></app-quick-action>
    </div>
  </div>
</ng-template>

<ngb-alert type="danger" *ngIf="alertText.length > 0" (closed)="closeAlert()">
  {{alertText}}</ngb-alert>
  