import { DiagnosisType } from './diagnosis-type';

export class DiagnosisItem {
    private _showDetails = false;
    readonly date: string;

    constructor(
        readonly title: string,
        readonly text: string,
        readonly errorCode: string,
        readonly type: DiagnosisType
    ) {
        this.date = new Date().toLocaleString();
    }

    get showDetails(): boolean {
        return this._showDetails;
    }

    toggleShowDetails(): void {
        this._showDetails = !this._showDetails;
    }
}
