import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Profile } from 'src/app/models/profile/profile';
import { ProfileService } from 'src/app/service/profile/profile.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  private carId: number;
  selectedProfile: Profile;

  constructor(
    private route: ActivatedRoute,
    private profileService: ProfileService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.carId = +params.get('carId');
    });
    this.selectedProfile = this.profileService.getByCarId(this.carId);
  }

  backLink(): string {
    return '/cars/' + this.carId;
  }
}
