import { Component, Input, OnInit } from '@angular/core';
import { FuelSystem } from 'src/app/models/car/powertrain/fuel-system';

@Component({
  selector: 'app-fuel-info',
  templateUrl: './fuel-info.component.html',
  styleUrls: ['./fuel-info.component.css']
})
export class FuelInfoComponent implements OnInit {

  @Input() fuelSystem: FuelSystem;
  @Input() rangeWarningInKm: number;

  constructor() { }

  ngOnInit(): void {
  }

  isFuelLow(): boolean {
    return +this.fuelSystem.getRemainingRangeInKm().toFixed(2) <= this.rangeWarningInKm;
  }
}
