import { Injectable } from '@angular/core';
import { BodyPosition } from 'src/app/models/car/body/body-position';
import { Light } from 'src/app/models/car/body/light';
import { Cabin } from 'src/app/models/car/cabin/cabin';
import { CabinPosition } from 'src/app/models/car/cabin/cabin-position';
import { CarDoor } from 'src/app/models/car/cabin/car-door';
import { CarWindow } from 'src/app/models/car/cabin/car-window';
import { ClimateControl } from 'src/app/models/car/cabin/climate-control';
import { Car } from 'src/app/models/car/car';
import { ChassisPosition } from 'src/app/models/car/chassis/chassis-position';
import { Wheel } from 'src/app/models/car/chassis/wheel';
import { Obd } from 'src/app/models/car/obd/obd';
import { FuelSystem } from 'src/app/models/car/powertrain/fuel-system';
import { Temperature } from 'src/app/models/weather/temperature';
import { TemperatureUnit } from 'src/app/models/weather/temperature-unit';

@Injectable({
  providedIn: 'root'
})
export class CarService {
  readonly cars: Car[] = [];

  constructor() {
    this.cars.push(this.createCustomizableLockStatusMockCar('Locked', true, true));
    this.cars.push(this.createRandomizedLockStatusMockCar('Random'));
    this.cars.push(this.createCustomMockCar('Custom', [true, true, false, false, false], [true, true, false, false]));
  }

  // ========== CUSTOM MOCK CAR ==========
  private createCustomMockCar(name: string, doorsConfig: boolean[], windowsConfig: boolean[]): Car {
    const cabin = this.createCabin(doorsConfig, windowsConfig);
    const wheels = this.createWheels();
    const lights = this.createLights();
    const climateControl = this.createClimateControl();
    const fuelSystem = this.createFuelSystem();
    const obd = new Obd([]);

    return new Car(name, cabin, wheels, lights, climateControl, fuelSystem, obd);
  }

  private createCabin(doorsConfig: boolean[], windowsConfig: boolean[]): Cabin {
    const doors = this.createDoors(doorsConfig);
    const windows = this.createWindows(windowsConfig);
    return new Cabin(doors, windows);
  }

  private createDoors(doorStatus: boolean[]): CarDoor[] {
    const carDoors: CarDoor[] = [];
    let index = 0;

    Object.values(CabinPosition).forEach(cabinPosition => {
      carDoors.push(new CarDoor(cabinPosition, doorStatus[index]));
      index++;
    });

    return carDoors;
  }

  private createWindows(windowStatus: boolean[]): CarWindow[] {
    const carWindows: CarWindow[] = [];
    let index = 0;

    Object.values(CabinPosition).forEach(cabinPosition => {
      if (cabinPosition !== CabinPosition.Trunk) {
        carWindows.push(new CarWindow(cabinPosition, windowStatus[index]));
        index++;
      }
    });

    return carWindows;
  }

  private createWheels(): Wheel[] {
    const wheels: Wheel[] = [];

    Object.values(ChassisPosition).forEach(chassisPosition => {
      wheels.push(new Wheel(chassisPosition, 2.5));
    });

    return wheels;
  }

  private createLights(): Light[] {
    const lights: Light[] = [];

    Object.values(BodyPosition).forEach(chassisPosition => {
      lights.push(new Light(chassisPosition));
    });

    return lights;
  }

  private createClimateControl(): ClimateControl {
    return new ClimateControl(
      new Temperature(23, TemperatureUnit.C),
      new Temperature(15, TemperatureUnit.C),
    );
  }

  private createFuelSystem(): FuelSystem {
    return new FuelSystem(50, 'Super E10', 50, 5.7);
  }

  // ========== CUSTOMIZABLE LOCK STATUS MOCK CAR ==========
  private createCustomizableLockStatusMockCar(name: string, doorsLockStatus: boolean, windowsLockStatus: boolean): Car {
    const doorStatus = this.createCustomizedDoorLockStatus(doorsLockStatus);
    const windowStatus = this.createCustomizedWindowLockStatus(windowsLockStatus);

    return this.createCustomMockCar(name, doorStatus, windowStatus);
  }

  private createCustomizedDoorLockStatus(doorsLockStatus: boolean): boolean[] {
    const doorStatus: boolean[] = [];

    Object.values(CabinPosition).forEach(() => {
      doorStatus.push(doorsLockStatus);
    });

    return doorStatus;
  }

  private createCustomizedWindowLockStatus(windowsLockStatus: boolean): boolean[] {
    const windowStatus: boolean[] = [];

    Object.values(CabinPosition).forEach(cabinPosition => {
      if (cabinPosition !== CabinPosition.Trunk) {
        windowStatus.push(windowsLockStatus);
      }
    });

    return windowStatus;
  }

  // ========== RANDOMIZED MOCK CAR ==========
  private createRandomizedLockStatusMockCar(name: string): Car {
    const doorStatus = this.createRandomDoorLockStatus();
    const windowStatus = this.createRandomWindowLockStatus();

    return this.createCustomMockCar(name, doorStatus, windowStatus);
  }

  private createRandomDoorLockStatus(): boolean[] {
    const doorsConfig: boolean[] = [];

    Object.values(CabinPosition).forEach(() => {
      doorsConfig.push(Math.random() < 0.5);
    });

    return doorsConfig;
  }

  private createRandomWindowLockStatus(): boolean[] {
    const windowsConfig: boolean[] = [];

    Object.values(CabinPosition).forEach(cabinPosition => {
      if (cabinPosition !== CabinPosition.Trunk) {
        windowsConfig.push(Math.random() < 0.5);
      }
    });

    return windowsConfig;
  }

  // ========== CAR SERVICE INTERFACE ==========
  getCarNames(): string[] {
    return this.cars.map(car => car.name);
  }

  getById(id: number): Car {
    return this.cars.find(car => car.id === id);
  }
}
