import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bosch';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.initializeIcons();
  }

  initializeIcons(): void {
    // <mat-icon svgIcon="fuel_station" [ngStyle]="{'fill':'green'}"></mat-icon>
    const iconNames = [
      'car',
      'caret-down-fill',
      'caret-up-fill',
      'catalyst',
      'exclamation-mark',
      'fuel_station',
      'gps',
      'lambda',
      'light',
      'lock_closed',
      'lock_open',
      'magnifier',
      'repair_station',
      'send',
      'settings',
      'snowflake',
      'thermometer_off',
      'thermometer_on',
      'thumb_up',
      'tire',
      'warning_triangle',
      'window_closed',
      'window_open'
    ];

    iconNames.forEach(iconName => this.initializeIcon(iconName));
  }

  initializeIcon(iconName: string): void {
    this.matIconRegistry.addSvgIcon(iconName,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/' + iconName + '.svg'));
  }
}
