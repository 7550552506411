import { DiagnosisType } from '../diagnosis/diagnosis-type';
import { DiagnosisItem } from '../diagnosis/diagnosis-item';
import { BodyPosition } from './body-position';

export class Light {
  warning = new DiagnosisItem(
    'Licht defekt',
    'Das Licht ' + this.bodyPosition + ' ist defekt. Tauschen Sie die Glühbirne aus.',
    'B2391', DiagnosisType.warning
  );

  constructor(
    readonly bodyPosition: BodyPosition,
    public isBroken: boolean = false,
  ) { }

  break(): void {
    this.isBroken = true;
  }

  repair(): void {
    this.isBroken = false;
  }
}
