<div class="button-size">
    <div class="d-flex justify-content-center">
        <div class="m-2 btn button-type d-flex flex-column justify-content-center" (click)="handleClick()">
            <mat-icon class="align-self-center" svgIcon="{{icon}}"></mat-icon>
        </div>
    </div>
    <div class="bold d-flex justify-content-center text-center">
        {{name}}
    </div>
</div>
