import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Car } from 'src/app/models/car/car';
import { Profile } from 'src/app/models/profile/profile';
import { Weather } from 'src/app/models/weather/weather';
import { CarService } from 'src/app/service/car/car.service';
import { ProfileService } from 'src/app/service/profile/profile.service';
import { WeatherService } from 'src/app/service/weather/weather.service';

@Component({
  selector: 'app-failure',
  templateUrl: './failure.component.html',
  styleUrls: ['./failure.component.css']
})
export class FailureComponent implements OnInit {
  private selectedCar: Car;
  private selectedProfile: Profile;

  weather: Weather;
  fuelSystemFailure = false;
  windowFailure = false;
  wheelFailure = false;
  lightFailure = false;
  lambdaFailure = false;
  catalystFailure = false;

  constructor(
    private location: Location,
    private carService: CarService,
    private profileService: ProfileService,
    private weatherService: WeatherService
  ) { }

  ngOnInit(): void {
    this.location.onUrlChange(() => {
      const carId = parseInt(this.location.path().split('/')[2], 10);
      this.selectedCar = this.carService.getById(carId);
      this.selectedProfile = this.profileService.getByCarId(carId);
    });

    this.weather = this.weatherService.weather;
  }

  handleFuelSystem(): void {
    const fuelSystem = this.selectedCar.fuelSystem;

    if (this.fuelSystemFailure) {
      fuelSystem.currentTankLevelInLiters = fuelSystem.tankCapacityInLiters;
    } else {
      fuelSystem.currentTankLevelInLiters =
        Math.floor((this.selectedProfile.rangeWarningInKm - 1) * fuelSystem.averageConsumptionInLitersPer100Km / 100);
    }

    this.fuelSystemFailure = !this.fuelSystemFailure;
  }

  handleWindows(): void {
    const windows = this.selectedCar.cabin.windows;

    if (this.windowFailure) {
      windows.forEach(window => window.repair());
    } else {
      windows[this.randomIndex(windows.length)].break();
    }

    this.windowFailure = !this.windowFailure;
  }

  handleWheels(): void {
    const wheels = this.selectedCar.wheels;

    if (this.wheelFailure) {
      wheels.forEach(wheel => wheel.pressureInBar = 2.5);
    } else {
      wheels[this.randomIndex(wheels.length)].updatePressure(this.selectedProfile.wheelPressureWarningInBar - 0.1);
    }

    this.wheelFailure = !this.wheelFailure;
  }

  handleLights(): void {
    const lights = this.selectedCar.lights;

    if (this.lightFailure) {
      lights.forEach(light => light.repair());
    } else {
      lights[this.randomIndex(lights.length)].break();
    }

    this.lightFailure = !this.lightFailure;
  }

  handleLambda(): void {
    const obd = this.selectedCar.obd;

    if (this.lambdaFailure) {
      obd.removeDiagnosisItem('P0137');
    } else {
      obd.createLambdaFailure();
    }

    this.lambdaFailure = !this.lambdaFailure;
  }

  handleCatalyst(): void {
    const obd = this.selectedCar.obd;

    if (this.catalystFailure) {
      obd.removeDiagnosisItem('P0420');
    } else {
      obd.createCatalystFailure();
    }

    this.catalystFailure = !this.catalystFailure;
  }

  randomIndex(length: number): number {
    return Math.floor(Math.random() * length);
  }

  toggleFreeze(): void {
    this.weather.isFreeze = !this.weather.isFreeze;
  }
}
