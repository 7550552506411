import { NgModule } from '@angular/core';
import { GenericComponentsModule } from './generics/generics.module';
import { DashboardComponentsModule } from './dashboard/dashboard.module';

@NgModule({
  imports: [
      GenericComponentsModule,
      DashboardComponentsModule,
  ],
  exports: [
      GenericComponentsModule,
  ]
})
export class ComponentsModule { }
